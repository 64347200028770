
import { defineComponent, ref } from 'vue'
import { getString } from "firebase/remote-config"
import { ConfigValue, remoteConfig, remoteConfigActivation } from '@/services/RemoteConfig'

export default defineComponent({
  name: 'Contact',

  setup() {
    const location = ref<string>('')
    const contact = ref<string>('')

    remoteConfigActivation.finally(() => {
      location.value = getString(remoteConfig, ConfigValue.location)
      contact.value = getString(remoteConfig, ConfigValue.contact)
    })

    return { location, contact }
  }
})

