import { firebaseApp } from '@/services/Firebase'
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config"

// Make sure firebaseApp is initialized/callable before remoteConfig is fetched:
firebaseApp

export const remoteConfig = getRemoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 10000 // 3600000


export enum ConfigValue {
  frontpage_maintext = 'frontpage_maintext',
  frontpage_subtext = 'frontpage_subtext',
  frontpage_bigtext = 'frontpage_bigtext',

  team_headline = 'team_headline',
  team_maintext = 'team_maintext',
  team_subtext = 'team_subtext',
  team_buttonheader = 'team_buttonheader',
  team_buttontext = 'team_buttontext',

  jobs_headline = 'jobs_headline',
  jobs_maintext = 'jobs_maintext',
  jobs_subtext = 'jobs_subtext',
  jobs = 'jobs',

  contact = 'contact',
  location = 'location',

  privacy_policy = 'privacy_policy',
  imprint = 'imprint'
}

// remoteConfig.defaultConfig = {
//   "sample_config": "Ein default value. BTW TODO!" //todo
// }

// Listen to this Promise to check, if the RCs where already fetched/activated
export const remoteConfigActivation = fetchAndActivate(remoteConfig)