
import { defineComponent, PropType, ref } from 'vue';
import { Contact } from '@/dto/Contact'
import { getAvatar } from '@/services/Firebase'

export default defineComponent({
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true
    }
  },

  setup() {
    const avatarImage = ref("")
    return { avatarImage }
  },

  mounted() {
    getAvatar(this.contact.id).then((url) => {
      this.avatarImage = url
    })
  }
})

