import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a0ebf73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid px-0" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-8" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", {
          id: "privacy",
          innerHTML: _ctx.privacy
        }, null, 8, _hoisted_4)
      ])
    ])
  ]))
}