
import { defineComponent, ref } from 'vue'
import { getString } from "firebase/remote-config"
import { ConfigValue, remoteConfig, remoteConfigActivation } from '@/services/RemoteConfig'

export default defineComponent({
  name: 'Imprint',

  setup() {
    const imprint = ref<string>('')

    remoteConfigActivation.finally(() => {
      imprint.value = getString(remoteConfig, ConfigValue.imprint)
    })

    return { imprint }
  }
})

