
import { defineComponent, reactive, ref } from 'vue'
import { getString } from "firebase/remote-config"
import { ConfigValue, remoteConfig, remoteConfigActivation } from '@/services/RemoteConfig'
import { Job } from '@/dto/Jobs'
import JobPreview from '@/views/components/JobPreview.vue'
import { getJobs } from '@/services/Firebase'

// Wrapper for Job[] to trigger reactive object change
class JobList {
  jobs: Job[] = []
}


export default defineComponent({
  name: 'Jobs',

  components: {
    JobPreview
  },

  setup() {
    const jobs = reactive(new JobList())

    const headertext = ref<string>('')
    const subheadertext = ref<string>('')
    const headline = ref<string>('')

    remoteConfigActivation.finally(() => {
      headline.value = getString(remoteConfig, ConfigValue.jobs_headline)
      headertext.value = getString(remoteConfig, ConfigValue.jobs_maintext)
      subheadertext.value = getString(remoteConfig, ConfigValue.jobs_subtext)
    })

    getJobs().then((fetchedJobs) => {
      jobs.jobs = fetchedJobs
    })

    return { jobs, headertext, subheadertext, headline }
  }
})

