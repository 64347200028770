export class Job {
  title: string
  available: boolean
  description?: string
  asset?: string
  jobAd?: string

  constructor(title: string, available: boolean, description?: string, asset?: string, jobAd?: string) {
    this.title = title
    this.description = description
    this.asset = asset
    this.jobAd = jobAd
    this.available = available
  }

  static fromDTO(obj: object): (Job | null) {
    if (obj === undefined || obj == null) {
      return null
    }

    const asJob = obj as Job
    if(asJob.title && asJob.available) {
      // Check if object is legit:
      // - required fields are not undefined
      // - job is available

      return asJob
    }

    return null
  }
}
