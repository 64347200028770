import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Team from '@/views/Team.vue'
import Jobs from '@/views/Jobs.vue'
import Contact from '@/views/Contact.vue'
import TeamMember from '@/views/TeamMember.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Imprint from '@/views/Imprint.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Team',
    name: 'Team',
    component: Team
  },
  {
    path: '/Jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Team/:id',
    name: 'TeamMember',
    component: TeamMember
  },
  {
    path: '/Imprint',
    name: 'Imprint',
    component: Imprint
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: PrivacyPolicy
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
  routes
})

export default router
