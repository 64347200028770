
import { defineComponent, ref } from 'vue'
import { getString } from "firebase/remote-config"
import { ConfigValue, remoteConfig, remoteConfigActivation } from '@/services/RemoteConfig'

export default defineComponent({
  name: 'PrivacyPolicy',

  setup() {
    const privacy = ref<string>('')

    remoteConfigActivation.finally(() => {
      privacy.value = getString(remoteConfig, ConfigValue.privacy_policy)
    })

    return { privacy }
  }
})

