import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Bootstrap responsive design
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

// Importing runs initial firebase code
import '@/services/Firebase'
console.log('okok')

// Dynamic head/meta-tags per component
// https://github.com/vueuse/head
import { createHead } from '@vueuse/head'

// Setup App
const head = createHead()
const app = createApp(App).use(router).use(head)

app.mount('#app')
