import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getStorage } from "firebase/storage"
import { getFirestore, doc, getDoc, getDocs, collection } from "firebase/firestore";

import { getDownloadURL, ref as storageRef } from "firebase/storage"

import { Contact } from '@/dto/Contact'
import { Job } from '@/dto/Jobs'


const firebaseConfig = {
  apiKey: 'AIzaSyCuJnOgvhD_DmXXoFJQbjYuNfWiR7Rk1pY',
  authDomain: 'website-pixeltrend.firebaseapp.com',
  projectId: 'website-pixeltrend',
  storageBucket: 'website-pixeltrend.appspot.com',
  messagingSenderId: '1072075999367',
  appId: '1:1072075999367:web:88905fb3dc08d622dcd022',
  measurementId: 'G-Q28PL6Q0T8'
}

console.log('init firebase app')
export const firebaseApp = initializeApp(firebaseConfig)
console.log('initted firebase app')

export const appStorage = getStorage(firebaseApp)
const db = getFirestore(firebaseApp)

const teamImages = require.context('@/assets/team/', false)
const jobImages = require.context('@/assets/jobs/', false)

export async function getAvatar(forContactId: string): Promise<string> {
  let avatarURL = ""

  await getDownloadURL(storageRef(appStorage, 'team/' + forContactId + '.jpg')).then((url) => {
    avatarURL = url
  }).catch(() => {
    // console.error(err)
    avatarURL = teamImages('./default.jpg')
  })

  return avatarURL
}

export async function getJobAsset(assetname?: string): Promise<string> {
  if(!assetname) {
    return jobImages('./default.png')
  }

  let assetURL = ""

  await getDownloadURL(storageRef(appStorage, 'jobs/' + assetname)).then((url) => {
    assetURL = url
  }).catch(() => {
    // console.error(err)
    assetURL = jobImages('./default.png')
  })

  return assetURL
}

export async function getTeamMember(id: string): Promise<Contact | null> {
  // const teamColl = await db.collection('/team').doc(id).get()
  const teamColl = await getDoc(doc(db, "team", id))
  if (!teamColl.exists()) {
    console.log("No such document!");
    return null
  }

  const contactData = teamColl.data()
  if (!contactData) { return null }
  
  return Contact.fromDTO(id, contactData as object)
}

export async function getTeam(): Promise<Contact[]> {
  // const teamColl = await db.collection('/team').get()
  const teamColl = await getDocs(collection(db, "team"))

  const team: Contact[] = []
  teamColl.forEach((contact) => {
    // doc.data() is never undefined for query doc snapshots
    const cont: Contact | null = Contact.fromDTO(contact.id, contact.data() as object)
    if (cont) {
      team.push(cont)
    }
  })

  return team
}

export async function getJobs(): Promise<Job[]> {
  // const jobColl = await db.collection('/jobs').get()
  const jobColl = await getDocs(collection(db, "jobs"))

  const jobs: Job[] = []

  jobColl.forEach((job) => {
    // doc.data() is never undefined for query doc snapshots
    const cont: Job | null = Job.fromDTO(job.data() as object)
    if (cont) {
      jobs.push(cont)
    }
  })

  return jobs
}
