
import { defineComponent, PropType, ref } from 'vue';
import { Job } from '@/dto/Jobs'
import { getJobAsset } from '@/services/Firebase'

export default defineComponent({
  props: {
    job: {
      type: Object as PropType<Job>,
      required: true
    }
  },

  setup() {
    const jobImage = ref('')
    const jobAd = ref('mailto:career@pixeltrend.at')

    return { jobImage, jobAd }
  },

  mounted() {
    getJobAsset(this.job.asset).then((jobAssetURL) => {
      this.jobImage = jobAssetURL
    })

    if (this.job.jobAd) {
      getJobAsset(this.job.jobAd).then((jobAdURL) => {
        this.jobAd = jobAdURL
      })
    }
  }
})

