
import { useRoute } from 'vue-router'
import { defineComponent, reactive, ref } from 'vue';
import { Contact } from '@/dto/Contact'
import { getTeamMember, getAvatar } from '@/services/Firebase'

class ContactState {
  loaded = false
  contact?: Contact
}

export default defineComponent({
  setup() {
    const contact = reactive(new ContactState())
    const contactId = useRoute().params.id as string

    getTeamMember(contactId).then((cont) => {
      console.log(cont)
      if (cont) {
        contact.contact = cont
      }
    }).finally(() => {
      contact.loaded = true
    })

    const avatarImage = ref("")
    getAvatar(contactId).then((url) => {
      avatarImage.value = url
    })

    return { contact, avatarImage }
  },

  computed: {
    fullname: function(): string {
      if (this.contact.contact?.lastname) {
        return this.contact.contact?.firstname + " " + this.contact.contact.lastname
      }

      return this.contact.contact?.firstname ?? ""
    },
    phonelink: function(): string {
      return "tel:" + this.contact.contact?.phone
    },
    maillink: function(): string {
      return "mailto:" + this.contact.contact?.mail
    },
    website: function(): string {
      if (!this.contact.contact?.website) {
        return "pixeltrend.at"
      }

      return this.contact.contact.website
    },
    websitelink: function(): string {
      if (!this.contact.contact?.website) {
        return "https://pixeltrend.at"
      }

      if (this.contact.contact.website.startsWith("http")) {
        return this.contact.contact.website
      }

      return "https://" + this.contact.contact.website
    }
  },

  methods: {
    downloadVCard: function() {
      if (!this.contact.contact) {
        console.error("Contact for vcard-creation unavailable.")
        return
      }

      const vcard = this.contact.contact?.vCard(this.avatarImage)

      console.log(vcard)

      if (!vcard) {
        return
      }

      var blob = new Blob([ vcard ], { "type" : "text/vcard" });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = this.contact.contact.firstname + '.vcf'
      link.click()
    }
  },

  watch: {
    '$route.params.id': {
      handler: function(contactId: string | undefined) {
        if (!contactId) { return }

        this.contact.loaded = false
        
        getTeamMember(contactId).then((cont) => {
          if (cont) {
            this.contact.contact = cont
          }
        }).finally(() => {
          this.contact.loaded = true
        })
      }
    }
  }
})

