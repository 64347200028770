
import { defineComponent, reactive, ref } from 'vue';
import { Contact } from '@/dto/Contact'
import { getTeam } from '@/services/Firebase'
import ContactPreview from '@/views/components/ContactPreview.vue'
import { getString } from "firebase/remote-config"
import { ConfigValue, remoteConfig, remoteConfigActivation } from '@/services/RemoteConfig'

class TeamState {
  loaded = false
  team: Contact[] = []
}

export default defineComponent({
  name: 'Team',

  components: {
    ContactPreview
  },

  setup() {
    const teamState = reactive(new TeamState())
    const headertext = ref<string>('')
    const subheadertext = ref<string>('')
    const buttonheader = ref<string>('')
    const buttontext = ref<string>('')
    const headline = ref<string>('')

    remoteConfigActivation.finally(() => {
      console.log("Hey super, die remoteconfigs haben fertig geladen im home yeah und juhu!")
      headertext.value = getString(remoteConfig, ConfigValue.team_maintext)
      subheadertext.value = getString(remoteConfig, ConfigValue.team_subtext)
      buttonheader.value = getString(remoteConfig, ConfigValue.team_buttonheader)
      buttontext.value = getString(remoteConfig, ConfigValue.team_buttontext)
      headline.value = getString(remoteConfig, ConfigValue.team_headline)
    })

    getTeam().then((team) => {
      teamState.team = team
    }).finally(() => {
      teamState.loaded = true
    })

    return { teamState, headertext, subheadertext, buttonheader, buttontext, headline }
  },

  methods: {
    contactImage: function(contactId: string): string {
      console.log("Looking for image for " + contactId)
      return "@/assets/team/default.jpg"
    }
  }
})

