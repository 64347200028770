import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26149342"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-3 worker" }
const _hoisted_2 = { class: "contact-name" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.contact.firstname), 1),
    _createElementVNode("img", { src: _ctx.avatarImage }, null, 8, _hoisted_3)
  ]))
}