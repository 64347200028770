export class Contact {
  id: string
  firstname: string
  lastname?: string
  info?: string
  website?: string
  phone?: string
  mail?: string
  job?: string


  constructor(id: string, firstname: string, lastname?: string, info?: string, website?: string, phone?: string, mail?: string, job?: string) {
    this.id = id
    this.firstname = firstname
    this.lastname = lastname
    this.info = info
    this.website = website
    this.phone = phone
    this.mail = mail
    this.job = job
  }

  vCard(avatarURL?: string): string {
    let vcard = "BEGIN:VCARD\nVERSION:4.0\n"

    vcard += "N:" + (this.lastname ?? "") + ";" + this.firstname + ";;;\n"

    if (this.phone) {
      //TEL;TYPE=CELL,HOME:(0170) 1234567
      // vcard += "TEL;TYPE=work,voice;VALUE=uri:tel:" + this.phone + "\n"
      vcard += "TEL;TYPE=VOICE,WORK:" + this.phone +"\n"
    }

    if (this.mail) {
      //EMAIL:erika@mustermann.de
      vcard += "EMAIL:" + this.mail + "\n"
    }

    if (this.job) {
      //ROLE:Executive
      //ORG:Google;GMail Team;Spam Detection Squad
      vcard += "ROLE:" + this.job + "\n"
      vcard += "ORG:Pixeltrend;" + this.job + ";\n"
    } else {
      vcard += "ORG:Pixeltrend;;\n"
    }

    //ADR;TYPE=home:;;Heidestrasse 17;Koeln;;51147;Germany
    vcard += "ADR;TYPE=office:;;Industriezeile 35;Linz;;4020;Austria\n"

    if (avatarURL) {
      //PHOTO;MEDIATYPE=image/jpeg:http://example.org/photo.jpg
      //PHOTO:data:image/jpeg;base64,[base64-data]
      vcard += "PHOTO;MEDIATYPE=image/jpeg:" + avatarURL + "\n"
    }
    
    //URL:http://www.mustermann.de
    //NOTE:Ich bin eine fiktive Person aus Deutschland\nund ich bin bundesweit bekannt.
    //LABEL;TYPE=HOME:Heidestrasse 17\n51147 Koeln\nDeutschland
    //GEO:geo: 50.858\,7.0885
  
    //LOGO;MEDIATYPE=image/png:http://example.org/logo.png
    //LOGO:data:image/png;base64,[base64-data]

    vcard += "END:VCARD"
    return vcard
  }

  static fromDTO(id: string, obj: object): Contact | null {
    if (obj === undefined || obj == null) {
      return null
    }

    const asCont = obj as Contact
    if (!asCont.firstname) {
      console.error('Received contact without firstname? This might not be a valid entry.')
      return null
    }

    const contact = new Contact(id, asCont.firstname, asCont.lastname, asCont.info, asCont.website, asCont.phone, asCont.mail, asCont.job)
    return contact
  }
}