
import { defineComponent, ref } from 'vue'
import { remoteConfigActivation } from '@/services/RemoteConfig'
import Splashscreen from '@/views/components/Splashscreen.vue'

export default defineComponent({
  setup() {
    const showSplashscreen = ref(true)

    let rcFetched = false
    let splashTimer = 2

    const intervalId = setInterval(() => {
      splashTimer -= 1
      console.log(splashTimer)
      if (splashTimer === 0) {
        clearInterval(intervalId)
        if (rcFetched) {
          console.log('set false')
          showSplashscreen.value = false
        }
      }
    }, 1000)

    remoteConfigActivation.finally(() => {
      rcFetched = true

      console.log('fetch done')
      if (splashTimer <= 0) {
        console.log('set false')
        showSplashscreen.value = false
      }
    })

    return { showSplashscreen }
  },

  components: {
    Splashscreen
  }
})
