
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    opacity() {
      return {
        'opacity': this.visible ? '1' : '0'
      }
    }
  }
})
