import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d36d6ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "job" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "job-text-wrapper" }
const _hoisted_5 = { class: "job-title" }
const _hoisted_6 = { class: "job-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.job.available)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.jobAd,
        target: "_blank",
        class: "col-12 col-md-6 col-xl-4 job-wrapper"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "job-image",
            src: _ctx.jobImage
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.job.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.job.description), 1)
          ])
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}