
import { defineComponent, ref } from 'vue';
import { getString } from "firebase/remote-config"
import { ConfigValue, remoteConfig, remoteConfigActivation } from '@/services/RemoteConfig'

export default defineComponent({
  name: 'Home',

  setup() {
    const headertext = ref<string>('')
    const subheadertext = ref<string>('')
    const bigtext = ref<string>('')
    
    remoteConfigActivation.finally(() => {
      headertext.value = getString(remoteConfig, ConfigValue.frontpage_maintext)
      subheadertext.value = getString(remoteConfig, ConfigValue.frontpage_subtext)
      bigtext.value = getString(remoteConfig, ConfigValue.frontpage_bigtext)
    })

    return { headertext, subheadertext, bigtext }
  },

  mounted() {
    this.headertext = "hallo"
  }
})
